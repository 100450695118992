<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1 p-relative">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          ข่าวสารทั้งหมด
        </h3>
      </div>

      <div
        v-for="(item,index) in NewsData"
        :key="index"
        class="txt-head-news d-flex justify-content-start mt-1 mb-2 cursor-pointer"
        @click="viewDetail(item)"
      >
        <div class="img-news mr-1 w-25">
          <img
            :src="item.img"
            alt=""
          >
        </div>

        <div class="news-detail w-75">
          <div class="txt-clip-title">
            <p class="title-top text-primary mb-0">
              {{ item.title }}
            </p>
          </div>

          <div class="txt-clip-detail mb-1">
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="detail-new text-muted"
              v-html="item.detail"
            />
          </div>

          <small class="text-muted">
            <i class="fal fa-calendar-alt" /> {{ showdate(item.created_at) }}
          </small>
        </div>
        <!-- <p class="title-top text-primary mb-0">
            {{ item.title }}
          </p>

          <p class="detail-new text-primary">
            {{ item.detail.substring(0, 170) + '...' }}
          </p> -->

        <!-- <small class="text-muted">
            <i class="fal fa-calendar-alt" /> เวลา 12:00 00/00/0000
          </small> -->
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    // ThisFooter,
  },
  data() {
    return {
      addedIds: [],
      Loadmore: false,
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      NewsData: JSON.parse(localStorage.getItem('NewsData')),
      balance: 0,
      price: null,
      MyCart: JSON.parse(localStorage.getItem('MyCart')),
    }
  },
  mounted() {
    this.GetNewAll()
  },
  methods: {
    async GetNewAll() {
      this.Loadmore = true
      try {
        const { data: res } = await this.$http.get('/lotto-suvarn/NewsIndexAll')
        this.NewsData = res
        this.Loadmore = false
      } catch (error) {
        console.log(error)
      }
    },
    viewDetail(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'news', params: { id: item._id } })
      localStorage.setItem('ViewNews', JSON.stringify(item))
    },
    showdate(date) {
      const date1 = new Date(date)
      const dd = String(date1.getDate()).padStart(2, '0')
      const mm = String(date1.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = date1.getFullYear()
      return `${dd}/${mm}/${yyyy}`
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">Success</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

  <style scoped></style>
